const API_VERSION = 'v1'

const PROJECT_PAGES = {
  PROJECT: 'Project',
  DASHBOARDS: 'Dashboards',
  COMPONENTS: 'Components',
  TAGS: 'Tags',
  TAG: 'Tag',
  GATEWAYS: 'Gateways',
  MQTT_GATEWAYS: 'MqttGateways',
  OPC_SERVERS: 'OpcServers',
  SCRIPTS: 'Scripts',
  NOTIFICATIONS: 'Notifications',
  NOTIFICATION_LIST: 'NotificationList',
  NOTIFICATION_TEMPLATES: 'NotificationTemplates',
  NOTIFICATION_TEMPLATE: 'NotificationTemplate',
  HISTORY: 'History',
  SETTINGS: 'Settings'
}

const PAGES = {
  AUTH: 'Auth',
  MAIN: 'Main',
  PROFILE: 'Profile',
  PROFILE_EDIT: 'ProfileEdit',
  USERS_MANAGEMENT: 'UsersManagement',
  USERS: 'Users',
  USER: 'User',
  USER_GROUP: 'UserGroup',
  USER_GROUP_USERS: 'UserGroupUsers',
  USER_GROUP_USERS_EDIT: 'UserGroupUsersEdit',
  USER_GROUP_PROJECTS: 'UserGroupProjects',
  USER_GROUP_PROJECTS_EDIT: 'UserGroupProjectsEdit',
  USER_GROUP_ACCESS: 'UserGroupAccess',
  USER_GROUP_ACCESS_EDIT: 'UserGroupAccessEdit',
  ORGANIZATION: 'Organization',
  ERROR: 'Error',
  ...PROJECT_PAGES
}

const THEMES = {
  LIGHT: 'light',
  DARK: 'dark'
}

export default {
  API_VERSION,
  PAGES,
  PROJECT_PAGES,
  THEMES
}
