import { computed, reactive, shallowRef } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { defineStore } from 'pinia'

import { APP } from '@/common/constants'
import { useProjectsApi } from '@/composables/api/http'
import { useProjectsWs } from '@/composables/api/ws'

const useProjects = defineStore('projects', () => {
  const route = useRoute()
  const router = useRouter()
  const projectsApi = reactive(useProjectsApi())

  const SOCKET_LISTENER_ID = 'projects'
  const projects = shallowRef([])
  const projectDataMap = reactive(new Map())
  const filters = reactive({
    q: null,
    hasWarnings: null,
    hasAlarms: null,
    notActive: null
  })

  const {
    listenProjects,
    stopListenProjects
  } = useProjectsWs(SOCKET_LISTENER_ID, {
    onMessage: onSocketUpdate,
    onReconnect: onSocketReconnect
  })

  const activeProject = computed(() => projects.value.find(project => project.id === route.params.projectId))
  const activeFilters = computed(() => Object.keys(filters).reduce((acc, f) =>
    filters[f]
      ? ++acc
      : acc, 0))
  const filteredProjects = computed(() =>
    activeFilters.value
      ? projects.value.filter(el => (!filters.q || el.title.toLowerCase().includes(filters.q?.toLowerCase()))
        && (!filters.hasWarnings || !!projectDataMap.get(el.id).notificationWarningCount)
        && (!filters.hasAlarms || !!projectDataMap.get(el.id).notificationAlarmCount)
        && (!filters.notActive
          || !!projectDataMap.get(el.id).tagOfflineCount && !projectDataMap.get(el.id).tagOnlineCount)
      )
      : projects.value)

  async function init() {
    return getProjects()
  }

  function destroy() {
    projects.value = []
    stopListenProjects()
  }

  async function getProjects() {
    const data = await projectsApi.getProjects()
    if (data.response) {
      projects.value = data.response.map(elem => ({
        id: elem.id,
        title: elem.title,
        description: elem.description,
        narrowTitle: elem.title.replaceAll(/[^a-zA-Zа-яА-ЯёЁ ]/g, '')
          .split(' ').slice(0, 2)
          .map(l => l[0]?.toUpperCase())
          .join(''),
        defaultRate: elem.defaultRate,
        defaultOfflineTimeout: elem.defaultOfflineTimeout,
        defaultStorageTimeLimit: elem.defaultStorageTimeLimit,
        defaultOffsetValue: elem.defaultOffsetValue,
        address: elem.address
      }))

      projectDataMap.clear()
      data.response.forEach(p => {
        projectDataMap.set(p.id, {
          tagOnlineCount: p.tagOnlineCount,
          tagOfflineCount: p.tagOfflineCount,
          notificationAlarmCount: p.notificationAlarmCount,
          notificationWarningCount: p.notificationWarningCount
        })
      })

      listenProjects(data.response.map(el => el.id))
    }

    return data.response
  }

  async function addProject({ title, description, address }) {
    const data = await projectsApi.addProject({
      title,
      description,
      address
    })
    if (data.response) {
      getProjects().then(r => {
        if (r) {
          router.push({ name: APP.PAGES.PROJECT, params: { projectId: data.response.id } })
        }
      })
    }

    return data
  }

  async function editProject(projectId, form) {
    const data = await projectsApi.editProject(projectId, form)
    if (data.response) {
      const index = projects.value.findIndex(el => el.id === projectId)
      if (~index) {
        projects.value = projects.value
          .slice(0, index)
          .concat({
            id: data.response.id,
            title: data.response.title,
            narrowTitle: data.response.title.replaceAll(/[^a-zA-Zа-яА-ЯёЁ ]/g, '')
              .split(' ').slice(0, 2)
              .map(l => l[0]?.toUpperCase())
              .join(''),
            description: data.response.description,
            defaultRate: data.response.defaultRate,
            defaultOfflineTimeout: data.response.defaultOfflineTimeout,
            defaultStorageTimeLimit: data.response.defaultStorageTimeLimit,
            defaultOffsetValue: data.response.defaultOffsetValue,
            address: data.response.address
          })
          .concat(projects.value.slice(index + 1))
      }
    }

    return data
  }

  async function deleteProject(projectId) {
    const data = await projectsApi.deleteProject(projectId)
    if (data.response) {
      projects.value = projects.value.filter(el => el.id !== projectId)
    }

    return data
  }

  function hasProject(projectId) {
    return projects.value.some(p => p.id === projectId)
  }

  /**
   * @param newFilters
   * @param {string=} newFilters.q
   * @param {boolean=} newFilters.hasWarnings
   * @param {boolean=} newFilters.hasAlarms
   */
  function updateFilters(newFilters) {
    ['q', 'hasWarnings', 'hasAlarms', 'notActive'].forEach(f => {
      if (f in newFilters) {
        filters[f] = newFilters[f]
      }
    })
  }

  function onSocketUpdate(socket) {
    if (socket.action === 'PROJECT_UPDATE' && hasProject(socket.data?.project.id)) {
      const projectData = socket.data.project
      if (projectDataMap.has(projectData.id)) {
        const current = projectDataMap.get(projectData.id)
        projectDataMap.set(projectData.id, {
          tagOnlineCount: Number.isInteger(projectData.tagOnlineCount)
            ? projectData.tagOnlineCount
            : current.tagOnlineCount,
          tagOfflineCount: Number.isInteger(projectData.tagOfflineCount)
            ? projectData.tagOfflineCount
            : current.tagOfflineCount,
          notificationAlarmCount: Number.isInteger(projectData.notificationAlarmCount)
            ? projectData.notificationAlarmCount
            : current.notificationAlarmCount,
          notificationWarningCount: Number.isInteger(projectData.notificationWarningCount)
            ? projectData.notificationWarningCount
            : current.notificationWarningCount
        })
      }
    }
  }

  function onSocketReconnect() {
    listenProjects(projects.value.map(el => el.id))
  }

  return {
    projects,
    projectDataMap,
    filteredProjects,
    activeProject,
    filters,
    activeFilters,
    loading: computed(() => projectsApi.loading),
    processing: computed(() => projectsApi.processing),
    init,
    destroy,
    addProject,
    editProject,
    deleteProject,
    hasProject,
    updateFilters
  }
})

export default useProjects
