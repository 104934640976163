import { reactive, shallowRef } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { parse } from 'qs'

import { API } from '@/common/constants'

/**
 * @param getRequest
 * @param options
 * @param options.bindParamsToUrl - Flag to show params in url
 * @param options.legacyPagination - Flag to work with pagination that starts from 1
 * @param options.requestParams - Request params with initial value
 * @param options.requestParamsMapper - Mapper that call right before request
 * @param options.requestResponseMapper - Mapper that call
 * @returns {{
 *  list: Ref<Array[]>,
 *  params: UnwrapNestedRefs<{ [p: string]: any }>
 *  pagination: UnwrapNestedRefs<{total: number, size: number, page: number}>,
 *  getList: getList,
 *  onSort: onSort,
 *  onUpdateFilter: onUpdateFilter,
 *  onUpdatePage: onUpdatePage,
 *  onUpdateSize: onUpdateSize,
 * }}
 */
export default function (getRequest, {
  bindParamsToUrl,
  legacyPagination,
  requestParams,
  requestParamsMapper,
  requestResponseMapper
}) {
  const router = useRouter()
  const route = useRoute()

  const list = shallowRef([])
  const params = reactive(requestParams || {})
  const pagination = reactive({
    page: 1,
    size: API.PAGINATION_SIZES.XS,
    total: null
  })

  initParams()

  /**
   * Request get resources
   * @param {Object} options
   * @param {boolean} options.resetPagination - flag to force reset pagination
   * @param {boolean} options.concatResponse - flag to concat response
   * @returns {Promise<any>}
   */
  async function getList(options = {}) {
    if (options.resetPagination) {
      resetPagination()
    }

    if (!options.concatResponse) {
      list.value = []
    }

    const preparedParams = requestParamsMapper?.(params) || params
    const data = await getRequest(preparedParams, {
      page: legacyPagination
        ? pagination.page - 1
        : pagination.page,
      size: pagination.size
    })
    if (data.response) {
      list.value = options.concatResponse
        ? list.value.concat(requestResponseMapper?.(data.response) || data.response)
        : requestResponseMapper?.(data.response) || data.response
      pagination.total = data.total
      if (pagination.total && pagination.total < pagination.page) {
        resetPagination()
        getList()
      }
    }

    return data
  }

  function initParams() {
    if (requestParams) {
      const queryParams = parse(route.query, { comma: true })
      Object.keys(requestParams).forEach(key => {
        params[key] = queryParams[key] || params[key]
      })

      const page = parseInt(queryParams.page)
      if (Number.isInteger(page)) {
        pagination.page = page
      }

      const size = parseInt(queryParams.size)
      if (Number.isInteger(size)) {
        pagination.size = size
      }
      updateQuery(true)
    }
  }

  function updateQuery(initial) {
    if (bindParamsToUrl) {
      const newQuery = Object.keys(params).reduce((acc, key) => {
        if (params[key]) {
          acc[key] = params[key]
        }
        return acc
      }, {})
      if (pagination.page > 1) {
        newQuery.page = pagination.page
      }
      if (pagination.size !== API.PAGINATION_SIZES.XS
        && Object.values(API.PAGINATION_SIZES).includes(pagination.size)) {
        newQuery.size = pagination.size
      }
      if (initial) {
        router.replace({ query: newQuery })
      } else {
        router.push({ query: newQuery })
      }
    }
  }

  function resetPagination() {
    pagination.page = 1
    pagination.total = null
    updateQuery()
  }

  /**
   * Sort data list
   * @param {string} field
   * @param {'ASC' | 'DESC'} direction
   */
  function onSort(field, direction) {
    params.sort = field
    params.sortDirection = direction
    resetPagination()
    getList()
  }

  /**
   * Update params
   * @param {[string, any][]} newParams - Array of params [param, value]
   * @param {boolean} suspense - Flag to suspense request on params change
   */
  function onUpdateFilter(newParams, suspense) {
    newParams?.forEach(param => {
      if (param[0] in params) {
        params[param[0]] = param[1]
      }
    })
    if (!suspense) {
      resetPagination()
      getList()
    } else {
      updateQuery()
    }
  }

  /**
   * Update pagination page
   * @param {number} page
   */
  function onUpdatePage(page) {
    pagination.page = page
    updateQuery()
    getList()
  }

  /**
   * Update pagination size
   * @param {number} size
   */
  function onUpdateSize(size) {
    pagination.size = size
    resetPagination()
    getList()
  }

  return {
    list,
    params,
    pagination,
    getList,
    onSort,
    onUpdateFilter,
    onUpdatePage,
    onUpdateSize
  }
}
